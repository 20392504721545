.blutext{
    color: #010156 !important;
}

.blu{
    background-color: #010156 !important;
}

.orangetext{
    color: #e65d0f !important;
}

.orange{
    background-color: #e65d0f !important;
}

.filter {
    font-size: large !important;
}

.my-fade-in {
    animation-duration: 60s;
}


.c-value-item .number {
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
    background: #f16821;
    color: #fff;
    line-height: 44px;
    font-weight: 300;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    margin-bottom: 40px; }
  
  .c-value-item h2 {
    font-size: 18px;
    margin-bottom: 30px;
    color: #fff; }
  
  .c-value-item p {
    color: white; }

@media (max-width: 768px) {
    .shift {
      width: 84% !important;
    }
  }
  